@charset "UTF-8";
.ui-img-grayscale {
  opacity: .4;
  -webkit-filter: grayscale(1);
          filter: grayscale(1); }
  .ui-img-grayscale.hoverable {
    -webkit-transition: all .3s;
    transition: all .3s; }
    .ui-img-grayscale.hoverable:hover, .ui-img-grayscale.hoverable:focus {
      opacity: 1;
      -webkit-filter: none;
              filter: none; }

.ui-w-140 {
  width: 140px !important;
  height: auto; }

.ui-w-120 {
  width: 120px !important;
  height: auto; }

.ui-w-100 {
  width: 100px !important;
  height: auto; }

.ui-w-80 {
  width: 80px !important;
  height: auto; }

.ui-w-60 {
  width: 60px !important;
  height: auto; }

.ui-w-50 {
  width: 50px !important;
  height: auto; }

.ui-w-40 {
  width: 40px !important;
  height: auto; }

.ui-w-30 {
  width: 30px !important;
  height: auto; }

.ui-w-20 {
  width: 20px !important;
  height: auto; }

.ui-mh-100vh {
  min-height: 100vh; }

.ui-square,
.ui-rect,
.ui-rect-30,
.ui-rect-60,
.ui-rect-67,
.ui-rect-75 {
  position: relative !important;
  display: block !important;
  padding-top: 100% !important;
  width: 100% !important; }

.ui-square {
  padding-top: 100% !important; }

.ui-rect {
  padding-top: 50% !important; }

.ui-rect-30 {
  padding-top: 30% !important; }

.ui-rect-60 {
  padding-top: 60% !important; }

.ui-rect-67 {
  padding-top: 67% !important; }

.ui-rect-75 {
  padding-top: 75% !important; }

.ui-square-content,
.ui-rect-content {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important; }

.ui-icon {
  display: inline-block;
  width: 46px;
  height: 46px;
  border: 2px solid;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  line-height: calc(46px - 4px); }
  .ui-icon.border-0 {
    line-height: 46px; }

.ui-icon-sm {
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: calc(30px - 4px); }
  .ui-icon-sm.border-0 {
    line-height: 30px; }

.ui-stars,
.ui-star,
.ui-star > * {
  height: 1.1em;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.ui-stars {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap; }

.ui-star {
  position: relative;
  display: block;
  float: left;
  width: 1.1em;
  height: 1.1em;
  text-decoration: none !important;
  font-size: 1.1em;
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  [dir=rtl] .ui-star {
    float: right; }
  .ui-star + .ui-star {
    margin-left: -0.1em; }
    [dir=rtl] .ui-star + .ui-star {
      margin-right: -0.1em;
      margin-left: 0; }
  .ui-star > *,
  .ui-star > *::before,
  .ui-star > *::after {
    position: absolute;
    left: 0.55em;
    height: 100%;
    font-size: 1em;
    line-height: 1;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    [dir=rtl] .ui-star > *, [dir=rtl]
    .ui-star > *::before, [dir=rtl]
    .ui-star > *::after {
      right: 0.55em;
      left: auto;
      -webkit-transform: translateX(50%);
              transform: translateX(50%); }
  .ui-star > * {
    top: 0;
    width: 100%;
    text-align: center; }
  .ui-star > *:first-child {
    z-index: 10;
    display: none;
    overflow: hidden;
    color: #FFE924; }
  .ui-star > *:last-child {
    z-index: 5;
    display: block; }
  .ui-star.half-filled > *:first-child {
    width: 50%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
    [dir=rtl] .ui-star.half-filled > *:first-child {
      -webkit-transform: translateX(100%);
              transform: translateX(100%); }
  .ui-star.filled > *:first-child,
  .ui-star.half-filled > *:first-child {
    display: block; }
  .ui-star.filled > *:last-child {
    display: none; }

.ui-stars.hoverable .ui-star > *:first-child {
  display: block; }

.ui-stars.hoverable .ui-star:first-child:not(.filled) > *:first-child,
.ui-stars.hoverable .ui-star:first-child:not(.filled) ~ .ui-star > *:first-child,
.ui-stars.hoverable .ui-star:first-child:not(.half-filled) > *:first-child,
.ui-stars.hoverable .ui-star:first-child:not(.half-filled) ~ .ui-star > *:first-child {
  display: none; }

.ui-stars.hoverable .ui-star.filled > *:first-child,
.ui-stars.hoverable .ui-star.half-filled > *:first-child {
  display: block !important; }

.ui-stars.hoverable:hover .ui-star > *:first-child {
  display: block !important;
  width: 100% !important;
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important; }
  [dir=rtl] .ui-stars.hoverable:hover .ui-star > *:first-child {
    -webkit-transform: translateX(50%) !important;
            transform: translateX(50%) !important; }

.ui-stars.hoverable .ui-star:hover ~ .ui-star > *:first-child {
  display: none !important; }

.ui-stars.hoverable .ui-star:hover ~ .ui-star > *:last-child {
  display: block !important; }

.ui-list {
  padding: 0; }
  .ui-list > li {
    margin-bottom: 8px;
    list-style: none; }
  .ui-list > li::before {
    content: "•";
    display: inline-block;
    margin-right: 12px;
    font-weight: 700; }
    [dir=rtl] .ui-list > li::before {
      margin-right: 0;
      margin-left: 12px; }

.ui-bg-cover {
  background-color: transparent;
  background-position: center center;
  background-size: cover; }

.ui-bg-fixed {
  background-attachment: fixed; }

.ui-bg-overlay-container,
.ui-bg-video-container {
  position: relative; }
  .ui-bg-overlay-container > *,
  .ui-bg-video-container > * {
    position: relative; }

.ui-bg-overlay-container .ui-bg-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block; }

.ui-bg-video-container .ui-bg-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow: hidden; }
  .ui-bg-video-container .ui-bg-video video {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center center;
       object-position: center center; }

.ui-text-block {
  line-height: 1.8; }
  .ui-text-block > p:last-child {
    margin-bottom: 0; }

.default-style .hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .6;
  font-size: 0.75rem; }

.default-style .hr-text:before {
  content: '';
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(24, 28, 33, 0.6)), to(transparent));
  background: linear-gradient(to right, transparent, rgba(24, 28, 33, 0.6), transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px; }

.default-style .hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 .5em;
  line-height: 1.5em;
  background-color: #fff; }

.default-style #card-body {
  overflow-y: auto;
  height: 100vh; }

.default-style div.media-body {
  font-size: 0.85rem !important;
  line-height: 1.25rem !important; }

.default-style table.chit-table th:last-child {
  text-align: right; }

.default-style table.chit-table td:last-child {
  text-align: right; }

.default-style table.chit-table.edit th:last-child {
  text-align: right; }

.default-style table.chit-table.edit td:last-child {
  text-align: right; }

.default-style table.chit-table.edit td:nth-child(1) {
  width: 60%; }

.default-style table.chit-table.edit td:nth-child(2) {
  width: 30%; }

.default-style table.chit-table.edit td:nth-child(3) {
  width: 10%; }

.default-style table.chit-table.edit th:nth-child(2) {
  text-align: right; }

.default-style table.chit-table.edit td:nth-child(2) {
  text-align: right; }

.default-style .bg-lighter td {
  font-weight: bold !important; }

.default-style tr.chit-totals {
  background-color: inherit;
  font-size: 110%;
  font-weight: bold; }

.default-style .form-label.hide {
  display: none !important; }

.default-style .ui-icon {
  border-color: rgba(24, 28, 33, 0.06); }

.default-style .ui-bordered {
  border: 1px solid rgba(24, 28, 33, 0.06); }

.default-style .ui-star > *:last-child {
  color: rgba(24, 28, 33, 0.1); }

.default-style .ui-list > li::before {
  color: rgba(24, 28, 33, 0.2); }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .default-style .ui-mh-100vh::after {
    content: '';
    display: block;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 0%;
            flex: 0 0 0%;
    min-height: 100vh;
    width: 0;
    font-size: 0; }
  .default-style .ui-mh-100vh.jumbotron::after {
    min-height: calc(100vh - 6rem); }
  .default-style .ui-mh-100vh.flex-column::after {
    display: none; } }

@media all and (min-width: 576px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .default-style .ui-mh-100vh.jumbotron::after {
    min-height: calc(100vh - 12rem); } }

.material-style .ui-icon {
  border-color: rgba(24, 28, 33, 0.06); }

.material-style .ui-bordered {
  border: 1px solid rgba(24, 28, 33, 0.06); }

.material-style .ui-star > *:last-child {
  color: rgba(24, 28, 33, 0.1); }

.material-style .ui-list > li::before {
  color: rgba(24, 28, 33, 0.2); }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .material-style .ui-mh-100vh::after {
    content: '';
    display: block;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 0%;
            flex: 0 0 0%;
    min-height: 100vh;
    width: 0;
    font-size: 0; }
  .material-style .ui-mh-100vh.jumbotron::after {
    min-height: calc(100vh - 6rem); }
  .material-style .ui-mh-100vh.flex-column::after {
    display: none; } }

@media all and (min-width: 576px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .material-style .ui-mh-100vh.jumbotron::after {
    min-height: calc(100vh - 12rem); } }

.badge.ui-product-badge {
  -ms-flex-item-align: start;
      align-self: flex-start;
  padding: 0.1875rem 0.375rem;
  font-size: 0.625rem; }

.ui-product-color {
  display: inline-block;
  overflow: hidden;
  margin: .144em;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 10rem;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
  vertical-align: middle; }

.ui-product-color-lg {
  width: 1rem;
  height: 1rem; }

.ui-product-color-sm {
  width: 0.75rem;
  height: 0.75rem; }

.ui-payment {
  height: 40px; }

.ui-payment-small {
  height: 30px; }

.ui-statistics-icon {
  width: 2.875rem;
  height: 2.875rem;
  font-size: 1.125rem;
  line-height: calc(2.875rem - 4px); }
  .ui-statistics-icon.border-0 {
    line-height: 2.875rem; }

.ui-feed-icon-container {
  position: relative; }

.ui-feed-icon {
  position: absolute;
  top: 0;
  right: -0.625rem;
  width: 1.25rem;
  height: 1.25rem;
  border: 0;
  font-size: 0.75rem;
  line-height: 1.25rem; }
  [dir=rtl] .ui-feed-icon {
    right: auto;
    left: -0.625rem; }

.ui-todo-item .custom-control-input:checked ~ .custom-control-label {
  text-decoration: line-through; }

.ui-todo-item .custom-control-input:checked ~ .ui-todo-badge,
.ui-todo-item .custom-control-input:checked ~ .custom-control-label .ui-todo-badge {
  display: none; }

.ui-activity-icon {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  line-height: calc(2.5rem - 4px); }
  .ui-activity-icon.border-0 {
    line-height: 2.5rem; }

.default-style .hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .6;
  font-size: 0.75rem; }

.default-style .hr-text:before {
  content: '';
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(24, 28, 33, 0.6)), to(transparent));
  background: linear-gradient(to right, transparent, rgba(24, 28, 33, 0.6), transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px; }

.default-style .hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 .5em;
  line-height: 1.5em;
  background-color: #fff; }

.default-style #card-body {
  overflow-y: auto;
  height: 100vh; }

.default-style div.media-body {
  font-size: 0.85rem !important;
  line-height: 1.25rem !important; }

.default-style table.chit-table th:last-child {
  text-align: right; }

.default-style table.chit-table td:last-child {
  text-align: right; }

.default-style table.chit-table.edit th:last-child {
  text-align: right; }

.default-style table.chit-table.edit td:last-child {
  text-align: right; }

.default-style table.chit-table.edit td:nth-child(1) {
  width: 60%; }

.default-style table.chit-table.edit td:nth-child(2) {
  width: 30%; }

.default-style table.chit-table.edit td:nth-child(3) {
  width: 10%; }

.default-style table.chit-table.edit th:nth-child(2) {
  text-align: right; }

.default-style table.chit-table.edit td:nth-child(2) {
  text-align: right; }

.default-style .bg-lighter td {
  font-weight: bold !important; }

.default-style tr.chit-totals {
  background-color: inherit;
  font-size: 110%;
  font-weight: bold; }

.default-style .form-label.hide {
  display: none !important; }

.default-style .ui-todo-item .custom-control-input:checked ~ .custom-control-label {
  color: #a3a4a6; }

.material-style .ui-todo-item .custom-control-input:checked ~ .custom-control-label {
  color: #a3a4a6; }

.ui-company-text {
  font-size: 15px;
  line-height: 1.8; }

.ui-device {
  position: relative;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }
  .ui-device:before {
    content: "";
    display: block;
    padding-top: 100%; }
  .ui-device .device-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%; }
  .ui-device .device-content {
    position: absolute;
    top: 0;
    z-index: 2;
    overflow: hidden; }
    .ui-device .device-content:before {
      content: "";
      display: block;
      padding-top: 100%; }
    .ui-device .device-content img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }

.ui-device.macbook {
  width: 1227px; }
  .ui-device.macbook:before {
    padding-top: 57.86471067644662%; }
  .ui-device.macbook .device-content {
    margin-top: 4.31947840260799%;
    margin-left: 12.46943765281174%;
    width: 75.15262428687857%; }
    [dir=rtl] .ui-device.macbook .device-content {
      margin-right: 12.46943765281174%;
      margin-left: 0; }
    .ui-device.macbook .device-content:before {
      padding-top: 62.47288503253796%; }

.ui-device.iphone {
  width: 302px; }
  .ui-device.iphone:before {
    padding-top: 209.154929577464789%; }
  .ui-device.iphone .device-content {
    z-index: 1;
    margin-top: 19.58041958041958%;
    margin-left: 4.580152671755725%;
    width: 91.608391608391608%;
    border-radius: 0 0 12% 12% / 0 0 4% 4%; }
    [dir=rtl] .ui-device.iphone .device-content {
      margin-right: 4.580152671755725%;
      margin-left: 0; }
    .ui-device.iphone .device-content:before {
      padding-top: 200.763358778625954%; }
  .ui-device.iphone .device-img {
    z-index: 2; }

.ui-window {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 23px;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .ui-window:before {
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
    height: 23px;
    background: #e5e5e5; }
  .ui-window .window-content {
    width: 100%; }
    .ui-window .window-content img {
      display: block;
      width: 100%; }

.ui-window:before, .ui-window:after,
.ui-window .window-content:before,
.ui-window .window-content:after {
  content: "";
  position: absolute;
  display: block; }

.ui-window:after,
.ui-window .window-content:before,
.ui-window .window-content:after {
  top: 6px;
  z-index: 4;
  width: 11px;
  height: 11px;
  border-radius: 50%; }

.ui-window:after {
  left: 10px;
  background: #ccc; }

.ui-window .window-content:before {
  left: 26px;
  background: #ccc; }

.ui-window .window-content:after {
  left: 43px;
  background: #ccc; }

.jumbotron.ui-hero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }
  .jumbotron.ui-hero > .container,
  .jumbotron.ui-hero > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .jumbotron.ui-hero > .container > .row,
    .jumbotron.ui-hero > .container-fluid > .row {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }

.ui-presentation-left, .ui-presentation-left.ui-device,
.ui-presentation-right,
.ui-presentation-right.ui-device {
  width: 100%; }

.ui-customer-review {
  font-size: 1.125rem;
  line-height: 1.75; }
  .ui-customer-review::before, .ui-customer-review::after {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5em;
    line-height: 1.125rem;
    opacity: .15; }
  .ui-customer-review:before {
    content: '“'; }
  .ui-customer-review:after {
    content: '”'; }

.default-style .hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .6;
  font-size: 0.75rem; }

.default-style .hr-text:before {
  content: '';
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(24, 28, 33, 0.6)), to(transparent));
  background: linear-gradient(to right, transparent, rgba(24, 28, 33, 0.6), transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px; }

.default-style .hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 .5em;
  line-height: 1.5em;
  background-color: #fff; }

.default-style #card-body {
  overflow-y: auto;
  height: 100vh; }

.default-style div.media-body {
  font-size: 0.85rem !important;
  line-height: 1.25rem !important; }

.default-style table.chit-table th:last-child {
  text-align: right; }

.default-style table.chit-table td:last-child {
  text-align: right; }

.default-style table.chit-table.edit th:last-child {
  text-align: right; }

.default-style table.chit-table.edit td:last-child {
  text-align: right; }

.default-style table.chit-table.edit td:nth-child(1) {
  width: 60%; }

.default-style table.chit-table.edit td:nth-child(2) {
  width: 30%; }

.default-style table.chit-table.edit td:nth-child(3) {
  width: 10%; }

.default-style table.chit-table.edit th:nth-child(2) {
  text-align: right; }

.default-style table.chit-table.edit td:nth-child(2) {
  text-align: right; }

.default-style .bg-lighter td {
  font-weight: bold !important; }

.default-style tr.chit-totals {
  background-color: inherit;
  font-size: 110%;
  font-weight: bold; }

.default-style .form-label.hide {
  display: none !important; }

.default-style .ui-hero > .container > .row {
  width: calc(100% + 1.5rem); }

.default-style .ui-customer-review {
  font-weight: 300; }
  .default-style .ui-customer-review::before, .default-style .ui-customer-review::after {
    font-weight: 900; }

@media (min-width: 992px) {
  .default-style .ui-presentation-left, .default-style .ui-presentation-left.ui-device,
  .default-style .ui-presentation-right,
  .default-style .ui-presentation-right.ui-device {
    width: 150%; } }

@media (min-width: 1200px) {
  .default-style .ui-presentation-left, .default-style .ui-presentation-left.ui-device,
  .default-style .ui-presentation-right,
  .default-style .ui-presentation-right.ui-device {
    width: 160%; } }

.material-style .ui-hero > .container > .row {
  width: calc(100% + 1.5rem); }

.material-style .ui-customer-review {
  font-weight: 300; }
  .material-style .ui-customer-review::before, .material-style .ui-customer-review::after {
    font-weight: 700; }

@media (min-width: 992px) {
  .material-style .ui-presentation-left, .material-style .ui-presentation-left.ui-device,
  .material-style .ui-presentation-right,
  .material-style .ui-presentation-right.ui-device {
    width: 150%; } }

@media (min-width: 1200px) {
  .material-style .ui-presentation-left, .material-style .ui-presentation-left.ui-device,
  .material-style .ui-presentation-right,
  .material-style .ui-presentation-right.ui-device {
    width: 160%; } }

.ui-play-icon {
  margin-right: -.25em; }

[dir=rtl] .ui-play-icon {
  margin-right: 0;
  margin-left: -.25em; }

.ui-img-grayscale {
  opacity: .4;
  -webkit-filter: grayscale();
  filter: grayscale();
}

.ui-img-grayscale.hoverable {
  transition: all .3s;
}

.ui-img-grayscale.hoverable:hover, .ui-img-grayscale.hoverable:focus {
  opacity: 1;
  -webkit-filter: none;
  filter: none;
}

.ui-w-140 {
  height: auto;
  width: 140px !important;
}

.ui-w-120 {
  height: auto;
  width: 120px !important;
}

.ui-w-100 {
  height: auto;
  width: 100px !important;
}

.ui-w-80 {
  height: auto;
  width: 80px !important;
}

.ui-w-60 {
  height: auto;
  width: 60px !important;
}

.ui-w-50 {
  height: auto;
  width: 50px !important;
}

.ui-w-40 {
  height: auto;
  width: 40px !important;
}

.ui-w-30 {
  height: auto;
  width: 30px !important;
}

.ui-w-20 {
  height: auto;
  width: 20px !important;
}

.ui-mh-100vh {
  min-height: 100vh;
}

.ui-square, .ui-rect, .ui-rect-30, .ui-rect-60, .ui-rect-67, .ui-rect-75 {
  width: 100% !important;
  padding-top: 100% !important;
  display: block !important;
  position: relative !important;
}

.ui-square {
  padding-top: 100% !important;
}

.ui-rect {
  padding-top: 50% !important;
}

.ui-rect-30 {
  padding-top: 30% !important;
}

.ui-rect-60 {
  padding-top: 60% !important;
}

.ui-rect-67 {
  padding-top: 67% !important;
}

.ui-rect-75 {
  padding-top: 75% !important;
}

.ui-square-content, .ui-rect-content {
  position: absolute !important;
  inset: 0 !important;
}

.ui-icon {
  width: 46px;
  height: 46px;
  vertical-align: middle;
  text-align: center;
  border: 2px solid;
  border-radius: 50%;
  font-size: 18px;
  line-height: 42px;
  display: inline-block;
}

.ui-icon.border-0 {
  line-height: 46px;
}

.ui-icon-sm {
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 26px;
}

.ui-icon-sm.border-0 {
  line-height: 30px;
}

.ui-stars, .ui-star, .ui-star > * {
  height: 1.1em;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.ui-stars {
  vertical-align: middle;
  white-space: nowrap;
  display: inline-block;
}

.ui-star {
  float: left;
  width: 1.1em;
  height: 1.1em;
  -webkit-user-select: none;
  user-select: none;
  font-size: 1.1em;
  line-height: 1;
  display: block;
  position: relative;
  text-decoration: none !important;
}

[dir="rtl"] .ui-star {
  float: right;
}

.ui-star + .ui-star {
  margin-left: -.1em;
}

[dir="rtl"] .ui-star + .ui-star {
  margin-left: 0;
  margin-right: -.1em;
}

.ui-star > *, .ui-star > :before, .ui-star > :after {
  height: 100%;
  font-size: 1em;
  line-height: 1;
  position: absolute;
  left: .55em;
  transform: translateX(-50%);
}

[dir="rtl"] .ui-star > *, [dir="rtl"] .ui-star > :before, [dir="rtl"] .ui-star > :after {
  left: auto;
  right: .55em;
  transform: translateX(50%);
}

.ui-star > * {
  width: 100%;
  text-align: center;
  top: 0;
}

.ui-star > :first-child {
  z-index: 10;
  color: #ffe924;
  display: none;
  overflow: hidden;
}

.ui-star > :last-child {
  z-index: 5;
  display: block;
}

.ui-star.half-filled > :first-child {
  width: 50%;
  transform: translateX(-100%);
}

[dir="rtl"] .ui-star.half-filled > :first-child {
  transform: translateX(100%);
}

.ui-star.filled > :first-child, .ui-star.half-filled > :first-child {
  display: block;
}

.ui-star.filled > :last-child {
  display: none;
}

.ui-stars.hoverable .ui-star > :first-child {
  display: block;
}

.ui-stars.hoverable .ui-star:first-child:not(.filled) > :first-child, .ui-stars.hoverable .ui-star:first-child:not(.filled) ~ .ui-star > :first-child, .ui-stars.hoverable .ui-star:first-child:not(.half-filled) > :first-child, .ui-stars.hoverable .ui-star:first-child:not(.half-filled) ~ .ui-star > :first-child {
  display: none;
}

.ui-stars.hoverable .ui-star.filled > :first-child, .ui-stars.hoverable .ui-star.half-filled > :first-child {
  display: block !important;
}

.ui-stars.hoverable:hover .ui-star > :first-child {
  width: 100% !important;
  display: block !important;
  transform: translateX(-50%) !important;
}

[dir="rtl"] .ui-stars.hoverable:hover .ui-star > :first-child {
  transform: translateX(50%) !important;
}

.ui-stars.hoverable .ui-star:hover ~ .ui-star > :first-child {
  display: none !important;
}

.ui-stars.hoverable .ui-star:hover ~ .ui-star > :last-child {
  display: block !important;
}

.ui-list {
  padding: 0;
}

.ui-list > li {
  margin-bottom: 8px;
  list-style: none;
}

.ui-list > li:before {
  content: "•";
  margin-right: 12px;
  font-weight: 700;
  display: inline-block;
}

[dir="rtl"] .ui-list > li:before {
  margin-left: 12px;
  margin-right: 0;
}

.ui-bg-cover {
  background-color: #0000;
  background-position: center;
  background-size: cover;
}

.ui-bg-fixed {
  background-attachment: fixed;
}

.ui-bg-overlay-container, .ui-bg-video-container, .ui-bg-overlay-container > *, .ui-bg-video-container > * {
  position: relative;
}

.ui-bg-overlay-container .ui-bg-overlay {
  display: block;
  position: absolute;
  inset: 0;
}

.ui-bg-video-container .ui-bg-video {
  display: block;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.ui-bg-video-container .ui-bg-video video {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

.ui-text-block {
  line-height: 1.8;
}

.ui-text-block > p:last-child {
  margin-bottom: 0;
}

.default-style .hr-text {
  color: #000;
  text-align: center;
  height: 1.5em;
  opacity: .6;
  border: 0;
  outline: 0;
  font-size: .75rem;
  line-height: 1em;
  position: relative;
}

.default-style .hr-text:before {
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#181c2199), to(transparent) );
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #0000, #181c2199, #0000);
  position: absolute;
  top: 50%;
  left: 0;
}

.default-style .hr-text:after {
  content: attr(data-content);
  background-color: #fff;
  padding: 0 .5em;
  line-height: 1.5em;
  display: inline-block;
  position: relative;
}

.default-style #card-body {
  height: 100vh;
  overflow-y: auto;
}

.default-style div.media-body {
  font-size: .85rem !important;
  line-height: 1.25rem !important;
}

.default-style table.chit-table th:last-child, .default-style table.chit-table td:last-child, .default-style table.chit-table.edit th:last-child, .default-style table.chit-table.edit td:last-child {
  text-align: right;
}

.default-style table.chit-table.edit td:nth-child(1) {
  width: 60%;
}

.default-style table.chit-table.edit td:nth-child(2) {
  width: 30%;
}

.default-style table.chit-table.edit td:nth-child(3) {
  width: 10%;
}

.default-style table.chit-table.edit th:nth-child(2), .default-style table.chit-table.edit td:nth-child(2) {
  text-align: right;
}

.default-style .bg-lighter td {
  font-weight: bold !important;
}

.default-style tr.chit-totals {
  background-color: inherit;
  font-size: 110%;
  font-weight: bold;
}

.default-style .form-label.hide {
  display: none !important;
}

.default-style .ui-icon {
  border-color: #181c210f;
}

.default-style .ui-bordered {
  border: 1px solid #181c210f;
}

.default-style .ui-star > :last-child {
  color: #181c211a;
}

.default-style .ui-list > li:before {
  color: #181c2133;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .default-style .ui-mh-100vh:after {
    content: "";
    min-height: 100vh;
    width: 0;
    flex: 0 0;
    font-size: 0;
    display: block;
  }

  .default-style .ui-mh-100vh.jumbotron:after {
    min-height: calc(100vh - 6rem);
  }

  .default-style .ui-mh-100vh.flex-column:after {
    display: none;
  }
}

@media (min-width: 576px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .default-style .ui-mh-100vh.jumbotron:after {
    min-height: calc(100vh - 12rem);
  }
}

.material-style .ui-icon {
  border-color: #181c210f;
}

.material-style .ui-bordered {
  border: 1px solid #181c210f;
}

.material-style .ui-star > :last-child {
  color: #181c211a;
}

.material-style .ui-list > li:before {
  color: #181c2133;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .material-style .ui-mh-100vh:after {
    content: "";
    min-height: 100vh;
    width: 0;
    flex: 0 0;
    font-size: 0;
    display: block;
  }

  .material-style .ui-mh-100vh.jumbotron:after {
    min-height: calc(100vh - 6rem);
  }

  .material-style .ui-mh-100vh.flex-column:after {
    display: none;
  }
}

@media (min-width: 576px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .material-style .ui-mh-100vh.jumbotron:after {
    min-height: calc(100vh - 12rem);
  }
}

.badge.ui-product-badge {
  align-self: flex-start;
  padding: .1875rem .375rem;
  font-size: .625rem;
}

.ui-product-color {
  width: .875rem;
  height: .875rem;
  vertical-align: middle;
  border-radius: 10rem;
  margin: .144em;
  display: inline-block;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px #00000026;
}

.ui-product-color-lg {
  width: 1rem;
  height: 1rem;
}

.ui-product-color-sm {
  width: .75rem;
  height: .75rem;
}

.ui-payment {
  height: 40px;
}

.ui-payment-small {
  height: 30px;
}

.ui-statistics-icon {
  width: 2.875rem;
  height: 2.875rem;
  font-size: 1.125rem;
  line-height: calc(2.875rem - 4px);
}

.ui-statistics-icon.border-0 {
  line-height: 2.875rem;
}

.ui-feed-icon-container {
  position: relative;
}

.ui-feed-icon {
  width: 1.25rem;
  height: 1.25rem;
  border: 0;
  font-size: .75rem;
  line-height: 1.25rem;
  position: absolute;
  top: 0;
  right: -.625rem;
}

[dir="rtl"] .ui-feed-icon {
  left: -.625rem;
  right: auto;
}

.ui-todo-item .custom-control-input:checked ~ .custom-control-label {
  text-decoration: line-through;
}

.ui-todo-item .custom-control-input:checked ~ .ui-todo-badge, .ui-todo-item .custom-control-input:checked ~ .custom-control-label .ui-todo-badge {
  display: none;
}

.ui-activity-icon {
  width: 2.5rem;
  height: 2.5rem;
  font-size: .875rem;
  line-height: calc(2.5rem - 4px);
}

.ui-activity-icon.border-0 {
  line-height: 2.5rem;
}

.default-style .hr-text {
  color: #000;
  text-align: center;
  height: 1.5em;
  opacity: .6;
  border: 0;
  outline: 0;
  font-size: .75rem;
  line-height: 1em;
  position: relative;
}

.default-style .hr-text:before {
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#181c2199), to(transparent) );
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #0000, #181c2199, #0000);
  position: absolute;
  top: 50%;
  left: 0;
}

.default-style .hr-text:after {
  content: attr(data-content);
  background-color: #fff;
  padding: 0 .5em;
  line-height: 1.5em;
  display: inline-block;
  position: relative;
}

.default-style #card-body {
  height: 100vh;
  overflow-y: auto;
}

.default-style div.media-body {
  font-size: .85rem !important;
  line-height: 1.25rem !important;
}

.default-style table.chit-table th:last-child, .default-style table.chit-table td:last-child, .default-style table.chit-table.edit th:last-child, .default-style table.chit-table.edit td:last-child {
  text-align: right;
}

.default-style table.chit-table.edit td:nth-child(1) {
  width: 60%;
}

.default-style table.chit-table.edit td:nth-child(2) {
  width: 30%;
}

.default-style table.chit-table.edit td:nth-child(3) {
  width: 10%;
}

.default-style table.chit-table.edit th:nth-child(2), .default-style table.chit-table.edit td:nth-child(2) {
  text-align: right;
}

.default-style .bg-lighter td {
  font-weight: bold !important;
}

.default-style tr.chit-totals {
  background-color: inherit;
  font-size: 110%;
  font-weight: bold;
}

.default-style .form-label.hide {
  display: none !important;
}

.default-style .ui-todo-item .custom-control-input:checked ~ .custom-control-label, .material-style .ui-todo-item .custom-control-input:checked ~ .custom-control-label {
  color: #a3a4a6;
}

.ui-company-text {
  font-size: 15px;
  line-height: 1.8;
}

.ui-device {
  position: relative;
  -webkit-user-select: none !important;
  user-select: none !important;
}

.ui-device:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.ui-device .device-img {
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ui-device .device-content {
  z-index: 2;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.ui-device .device-content:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.ui-device .device-content img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ui-device.macbook {
  width: 1227px;
}

.ui-device.macbook:before {
  padding-top: 57.8647%;
}

.ui-device.macbook .device-content {
  width: 75.1526%;
  margin-top: 4.31948%;
  margin-left: 12.4694%;
}

[dir="rtl"] .ui-device.macbook .device-content {
  margin-left: 0;
  margin-right: 12.4694%;
}

.ui-device.macbook .device-content:before {
  padding-top: 62.4729%;
}

.ui-device.iphone {
  width: 302px;
}

.ui-device.iphone:before {
  padding-top: 209.155%;
}

.ui-device.iphone .device-content {
  z-index: 1;
  width: 91.6084%;
  border-radius: 0 0 12% 12% / 0 0 4% 4%;
  margin-top: 19.5804%;
  margin-left: 4.58015%;
}

[dir="rtl"] .ui-device.iphone .device-content {
  margin-left: 0;
  margin-right: 4.58015%;
}

.ui-device.iphone .device-content:before {
  padding-top: 200.763%;
}

.ui-device.iphone .device-img {
  z-index: 2;
}

.ui-window {
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  padding-top: 23px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 10px #0000001f;
}

.ui-window:before {
  z-index: 3;
  height: 23px;
  background: #e5e5e5;
  top: 0;
  left: 0;
  right: 0;
}

.ui-window .window-content {
  width: 100%;
}

.ui-window .window-content img {
  width: 100%;
  display: block;
}

.ui-window:before, .ui-window:after, .ui-window .window-content:before, .ui-window .window-content:after {
  content: "";
  display: block;
  position: absolute;
}

.ui-window:after, .ui-window .window-content:before, .ui-window .window-content:after {
  z-index: 4;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  top: 6px;
}

.ui-window:after {
  background: #ccc;
  left: 10px;
}

.ui-window .window-content:before {
  background: #ccc;
  left: 26px;
}

.ui-window .window-content:after {
  background: #ccc;
  left: 43px;
}

.jumbotron.ui-hero {
  border-radius: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.jumbotron.ui-hero > .container, .jumbotron.ui-hero > .container-fluid {
  display: flex;
}

.jumbotron.ui-hero > .container > .row, .jumbotron.ui-hero > .container-fluid > .row {
  align-items: center;
}

.ui-presentation-left, .ui-presentation-left.ui-device, .ui-presentation-right, .ui-presentation-right.ui-device {
  width: 100%;
}

.ui-customer-review {
  font-size: 1.125rem;
  line-height: 1.75;
}

.ui-customer-review:before, .ui-customer-review:after {
  vertical-align: middle;
  opacity: .15;
  font-size: 1.5em;
  line-height: 1.125rem;
  display: inline-block;
}

.ui-customer-review:before {
  content: "“";
}

.ui-customer-review:after {
  content: "”";
}

.default-style .hr-text {
  color: #000;
  text-align: center;
  height: 1.5em;
  opacity: .6;
  border: 0;
  outline: 0;
  font-size: .75rem;
  line-height: 1em;
  position: relative;
}

.default-style .hr-text:before {
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#181c2199), to(transparent) );
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #0000, #181c2199, #0000);
  position: absolute;
  top: 50%;
  left: 0;
}

.default-style .hr-text:after {
  content: attr(data-content);
  background-color: #fff;
  padding: 0 .5em;
  line-height: 1.5em;
  display: inline-block;
  position: relative;
}

.default-style #card-body {
  height: 100vh;
  overflow-y: auto;
}

.default-style div.media-body {
  font-size: .85rem !important;
  line-height: 1.25rem !important;
}

.default-style table.chit-table th:last-child, .default-style table.chit-table td:last-child, .default-style table.chit-table.edit th:last-child, .default-style table.chit-table.edit td:last-child {
  text-align: right;
}

.default-style table.chit-table.edit td:nth-child(1) {
  width: 60%;
}

.default-style table.chit-table.edit td:nth-child(2) {
  width: 30%;
}

.default-style table.chit-table.edit td:nth-child(3) {
  width: 10%;
}

.default-style table.chit-table.edit th:nth-child(2), .default-style table.chit-table.edit td:nth-child(2) {
  text-align: right;
}

.default-style .bg-lighter td {
  font-weight: bold !important;
}

.default-style tr.chit-totals {
  background-color: inherit;
  font-size: 110%;
  font-weight: bold;
}

.default-style .form-label.hide {
  display: none !important;
}

.default-style .ui-hero > .container > .row {
  width: calc(100% + 1.5rem);
}

.default-style .ui-customer-review {
  font-weight: 300;
}

.default-style .ui-customer-review:before, .default-style .ui-customer-review:after {
  font-weight: 900;
}

@media (min-width: 992px) {
  .default-style .ui-presentation-left, .default-style .ui-presentation-left.ui-device, .default-style .ui-presentation-right, .default-style .ui-presentation-right.ui-device {
    width: 150%;
  }
}

@media (min-width: 1200px) {
  .default-style .ui-presentation-left, .default-style .ui-presentation-left.ui-device, .default-style .ui-presentation-right, .default-style .ui-presentation-right.ui-device {
    width: 160%;
  }
}

.material-style .ui-hero > .container > .row {
  width: calc(100% + 1.5rem);
}

.material-style .ui-customer-review {
  font-weight: 300;
}

.material-style .ui-customer-review:before, .material-style .ui-customer-review:after {
  font-weight: 700;
}

@media (min-width: 992px) {
  .material-style .ui-presentation-left, .material-style .ui-presentation-left.ui-device, .material-style .ui-presentation-right, .material-style .ui-presentation-right.ui-device {
    width: 150%;
  }
}

@media (min-width: 1200px) {
  .material-style .ui-presentation-left, .material-style .ui-presentation-left.ui-device, .material-style .ui-presentation-right, .material-style .ui-presentation-right.ui-device {
    width: 160%;
  }
}

.ui-play-icon {
  margin-right: -.25em;
}

[dir="rtl"] .ui-play-icon {
  margin-left: -.25em;
  margin-right: 0;
}

/*# sourceMappingURL=index.97b5734d.css.map */
